@import "~select2/dist/css/select2.min.css";
@import '~@fortawesome/fontawesome-pro/css/all.css';
@import "~flatpickr/dist/flatpickr.min.css";
body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
}

.table > :not(caption) > * > * {
  padding: 0.45rem 0.45rem;
}

.addTask a {
  color: #6d6d6d;
  font-weight: bold;
}

.circle {
  width: 100px; /* pas de grootte aan zoals je wilt */
  height: 100px;
  border: 2px solid #eed5ef; /* rand instellen */
  border-radius: 50%; /* maakt de div cirkelvormig */
  display: flex;
  background-color: #eed5ef;
  align-items: center; /* verticaal centreren van de tekst */
  justify-content: center; /* horizontaal centreren van de tekst */
  font-size: 16px; /* pas tekstgrootte aan */
  font-weight: bold; /* optioneel, maakt de tekst dikker */
  text-align: center; /* centreert de tekst in het midden */
}

body {
  margin-top: 20px;
  background: #90d2ff;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
.blink {
  animation: blink 1s step-start 0s infinite;
}

.card-box {
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 30px;
  background-color: #fff;
}

.thumb-sm {
  height: 36px;
  width: 36px;
}

.v-timeline-item .v-timeline-item__body {
  width: 100%;
}

.task-detail .task-dates li {
  float: left;
  width: 33.33333333%;
}

.task-detail .task-tags .bootstrap-tagsinput {
  padding: 0;
  border: none;
}

.task-detail .assign-team a {
  display: inline-block;
  margin: 5px 5px 5px 0;
}

.task-detail .files-list .file-box {
  display: inline-block;
  vertical-align: middle;
  width: 80px;
  padding: 2px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
}

.task-detail .files-list .file-box img {
  line-height: 70px;
}

.task-detail .files-list .file-box p {
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.add-new-plus {
  height: 32px;
  text-align: center;
  width: 32px;
  display: block;
  line-height: 32px;
  color: #98a6ad;
  font-weight: 700;
  background-color: #e3eaef;
  border-radius: 50%;
}

.project-sort-item .form-group {
  margin-right: 30px;
}

.project-sort-item .form-group:last-of-type {
  margin-right: 0;
}

.project-box {
  position: relative;
}

.project-box .badge {
  position: absolute;
  right: 20px;
}

.project-box h4 {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  overflow: hidden;
}

.project-box ul li {
  padding-right: 30px;
}

.project-box .project-members a {
  margin: 0 0 10px -12px;
  display: inline-block;
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.project-box .project-members a:first-of-type {
  margin-left: 0;
}

p {
  line-height: 1.6;
  font-size: 14px;
}

@media (max-width: 768px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding-right: 11px;
    padding-left: 11px;
    max-width: 100%;
  }
  .container-kanban {
    padding-right: 11px;
    padding-left: 11px;
    max-width: 100%;
  }
  .logo-large {
    display: none;
  }
}
@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding-left: 11px;
    padding-right: 11px;
    max-width: 100%;
  }
  .container-kanban {
    padding-left: 11px;
    padding-right: 11px;
    max-width: 95%;
  }
  .logo-large {
    display: block;
  }
}
@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 85%;
  }
  .container-kanban {
    max-width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }
  .logo-large {
    display: block;
  }
}
h1 {
  font-size: 22px;
  font-weight: bold;
}

a {
  text-decoration: none;
  color: #007bff;
}
a:hover {
  color: #0056b3;
}

.form-control {
  background-color: #fcfcfc;
}

.logo-large {
  margin: 20px;
  margin-top: 0px;
  display: inline-block;
}
.logo-large i {
  color: #3498db;
  font-size: 140px;
}

.icon-with-text {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.icon-with-text i {
  font-size: 24px; /* Icoon grootte */
  margin-right: 5px; /* Ruimte tussen het icoon en de tekst */
}

.icon-with-text span {
  font-size: 14px; /* Tekstgrootte */
}

.compact-p {
  margin-bottom: 5px; /* Pas deze waarde aan om de gewenste ruimte tussen de regels te bepalen */
}

.navbar {
  --bs-navbar-padding-y: 0px !important; /* Verwijder de standaard padding van de navbar */
}

[data-simplebar] {
  max-height: initial !important;
}

.menu-title {
  color: #000;
}

.task-card {
  position: relative;
}
.task-card .v-badge__wrapper {
  position: initial;
}
.task-card .custom-badge-position {
  position: absolute;
  right: 5px;
  left: initial !important;
  bottom: initial !important;
  top: -10px;
  font-weight: bold;
}
.task-card .custom-badge-position .v-badge__badge {
  font-weight: bold;
  position: initial !important;
}
.task-card-underline {
  font-size: 10px;
  color: #808080;
}
.task-card a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}

.modal .btn {
  color: #fff;
  font-weight: bold;
}

.dropdown-menu-first {
  position: absolute;
  height: auto;
  display: none;
  top: 100%;
  left: 0;
  padding: 0;
  border-radius: 0;
  white-space: nowrap;
}

.dropdown-main-inner:first-child .dropdown-main-item:hover {
  border-top: none;
}

.dropdown-main-inner:last-child .dropdown-main-item:hover {
  border-bottom: none;
}

.dropdown-menu-second {
  position: absolute;
  border: 0.8px solid lightgray !important;
  border-left: none !important;
  border-radius: 0;
  top: 0;
  left: 100%;
  width: auto;
  min-width: 600px;
  margin-top: -1px;
  margin-left: 1px;
  padding: 0;
  display: none;
}

.dropdown-main-inner {
  height: 32px;
}

.dropdown-menu-first {
  margin: -1px;
}

.dropdown-main-item {
  min-width: 200px;
}

.dropdown-main-item:hover {
  border: 0.8px solid lightgray;
  border-right: 1px solid white;
  border-left: none;
  font-weight: bold;
  margin-right: -1px;
}

.dropdown-main-inner:hover .dropdown-main-item {
  border: 0.8px solid lightgray;
  border-right: 1px solid white;
  border-left: none;
  font-weight: bold;
  margin-right: -1px;
}

.dropdown-main-item-inner-title {
  font-weight: bold;
  margin: 0;
}

.dropdown-menu-second-container {
  display: flex;
}

.dropdown-main-item-inner {
  min-width: 200px;
  width: auto;
  color: rgb(62, 184, 224);
}

.dropdown-main-item-inner:hover {
  background: none;
  color: rgb(165, 210, 224);
}

.dropdown-main-item-inner:active {
  background: none;
  color: rgb(62, 184, 224);
}

.dropdown-main:hover .dropdown-menu-first {
  display: block;
}

.dropdown-main-inner:hover .dropdown-menu-second {
  display: block;
}

.nav-link-btn {
  color: rgb(62, 184, 224);
}

.nav-link-btn:hover {
  border: 0.8px solid lightgray;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: rgb(62, 184, 224);
  margin: -1px;
  transition: none;
}

.mb-25 {
  margin-bottom: 85px;
}

.mb-50 {
  margin-bottom: 120px;
}

body {
  background-color: #fff;
}

.bg-gray-100 {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.mr-4 {
  margin-right: 1rem;
}

.flex {
  display: flex;
}

.column-width {
  min-width: 270px;
  width: 320px;
}

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

.columns-container {
  display: flex;
  gap: 10px; /* Optioneel: ruimte tussen de kolommen */
}

.min-h-screen {
  min-height: 100vh; /* Zorg ervoor dat de kolommen de volledige hoogte van het scherm gebruiken */
}

.overflow-x-auto {
  overflow-x: auto; /* Horizontaal scrollen als er te veel kolommen zijn */
}

.success-animation {
  margin: 150px auto;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}
.is-invalid {
  border-color: #dc3545; /* Rood */
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.5);
}

.select2-container.is-invalid .select2-selection {
  border-color: #dc3545; /* Rood */
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.5);
}

.hours-input.filled {
  background-color: #d4edda; /* Lichte groene achtergrond */
  transition: background-color 0.3s ease; /* Optioneel voor een smooth overgang */
}

.weekend-column {
  background-color: #f6f6f6 !important; /* Lichtroze */
}

.add-comment-btn.disabled {
  pointer-events: none;
  opacity: 0.5;
  background-color: #cccccc !important; /* Grijze achtergrond voor inactieve knoppen */
  border-color: #aaaaaa !important; /* Grijze rand */
}

.add-comment-btn.btn-success {
  background-color: #28a745 !important; /* Groene achtergrond */
  border-color: #28a745 !important;
  color: #fff !important; /* Witte tekst */
}

.add-comment-btn[disabled] {
  cursor: not-allowed;
  background-color: #e9ecef;
  color: #6c757d;
}

.large-checkbox {
  height: 2em;
  margin-left: 1.5em;
  width: 2em;
  margin-top: -1px;
}

#projecthourschar {
  margin-top: 15px;
  width: 100%;
}

.card.project {
  display: flex;
  justify-content: center;
  min-height: 200px;
  text-align: center;
}

.card-body.project {
  padding-bottom: 10px !important;
}

.card-text.project {
  display: flex;
  justify-content: center; /* Horizontaal centreren */
  align-items: center; /* Verticaal centreren */
  text-align: center; /* Centreert de tekst */
  font-size: large;
  font-weight: bold; /* Maakt de tekst vetgedrukt */
  text-align: center; /* Centreert de tekst */
}

.card-text-left.project {
  display: flex;
  justify-content: celeftnter; /* Horizontaal centreren */
  align-items: center; /* Verticaal centreren */
  text-align: left; /* Centreert de tekst */
  font-size: large;
  font-weight: bold; /* Maakt de tekst vetgedrukt */
  text-align: center; /* Centreert de tekst */
}

.small-text.project {
  font-size: 12px;
}

.project-description {
  max-height: 100px; /* Maximale hoogte van de div */
  overflow-y: auto; /* Zorgt voor een verticale scrollbalk indien nodig */
  padding: 0px; /* Optioneel, om ruimte binnen de div te geven */
  box-sizing: border-box; /* Zorgt ervoor dat padding en border worden meegerekend in de hoogte */
}

.project-nav {
  margin-top: 100px;
}

.circle-icon .circle-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3498db;
  display: inline-block;
  position: relative;
}
.circle-icon .circle-icon::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
}
.circle-icon .circle-icon:hover::after {
  opacity: 1;
  visibility: visible;
}